import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify';
import ElementUI from 'element-ui'; // 导入 Element UI
import 'element-ui/lib/theme-chalk/index.css'; // 引入 Element UI 样式
Vue.config.productionTip = false

Vue.use(Vuetify);

const vuetify = new Vuetify();
Vue.use(ElementUI); // 使用 Element UI
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
