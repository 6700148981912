<template>
  <div>
    <el-carousel height="100vh" direction="vertical" :autoplay="false" arrow="never" indicator-position="none"
      :loop="false" ref="carousel" @change="handleOuterChange" @wheel.native="handleWheel">
      <el-carousel-item class="carousel-item" style="display: flex;">
        <div class="item-content" :style="{ opacity: currentItemId === this.currentIndex + 1 ? 1 : 0 }">
          <img src="@/assets/svg/Ticker_Capial.svg" alt="Ticker Capial">
          <!-- 背景图-top -->
          <div class="background-image-top" :class="{ 'animate_top': isAnimating_top }"></div>

          <span class="grow_together">Grow Together for the Future in Web3</span>

          <!-- 背景图-down -->
          <div class="background-image-down" :class="{ 'animate_down': isAnimating_down }"></div>
        </div>
      </el-carousel-item>

      <!-- 外层轮播 官网简介-->
      <el-carousel-item class="company_profile">
        <div class="company_profile_content" :style="{ opacity: this.currentItemId === this.currentIndex ? 1 : 0 }">
          <span class="grow_together" style="color:#FFFFFF;line-height: 36px;display:block;">Since 2018, we have invested
            and incubated
            50+ blockchain projects</span>
          <span class="grow_together" style="color:#FFFFFF;line-height: 36px;display:block;">and formed long-term
            partnerships with top investors,</span>
          <span class="grow_together" style="color:#FFFFFF;line-height: 36px;display:block;">incubators and developer
            communities in China,</span>
          <span class="grow_together" style="color:#FFFFFF;line-height: 36px;display:block;">South Korea, Singapore,
            Israel, Southeast Asia and North America.</span>
        </div>
      </el-carousel-item>

      <!-- builders-fingerprint -->
      <el-carousel-item class="carousel-item" style="display: flex;">
        <img class="fingerprint" src="@/assets/svg/builders-fingerprint.svg"
          :style="{ opacity: this.currentItemId === this.currentIndex - 1 ? 1 : 0 }">
        <div class="inner-item-content" :style="{ opacity: this.currentItemId === this.currentIndex - 1 ? 1 : 0 }">
          <span class="title">{{ innerItems[0].title }}</span>
          <el-divider></el-divider>
          <span class="title_content">{{ innerItems[0].title_content }}</span>
        </div>
      </el-carousel-item>

      <!-- marketers-fingerprint -->
      <el-carousel-item class="carousel-item" style="display: flex;">
        <img class="fingerprint" src="@/assets/svg/marketers-fingerprint.svg"
          :style="{ opacity: this.currentItemId === this.currentIndex - 2 ? 1 : 0 }">
        <div class="inner-item-content" :style="{ opacity: this.currentItemId === this.currentIndex - 2 ? 1 : 0 }">
          <span class="title">{{ innerItems[1].title }}</span>
          <el-divider></el-divider>
          <span class="title_content">{{ innerItems[1].title_content }}</span>
        </div>
      </el-carousel-item>

      <!-- partners-fingerprint -->
      <el-carousel-item class="carousel-item" style="display: flex;">

        <img class="fingerprint" src="@/assets/svg/partners-fingerprint.svg"
          :style="{ opacity: this.currentItemId === this.currentIndex - 3 ? 1 : 0 }">
        <div class="inner-item-content" style="width: 736px;grid-template-rows: 1fr 1fr;"
          :style="{ opacity: this.currentItemId === this.currentIndex - 3 ? 1 : 0 }">
          <span class="title">{{ innerItems[2].title }}</span>
          <el-divider></el-divider>
          <span class="title_content" style="width: 600px;margin: auto;">{{ innerItems[2].title_content }}</span>
        </div>
      </el-carousel-item>

      <!-- 外层轮播 合作伙伴-->
      <el-carousel-item class="carousel-item" style="display: flex;background: #272727 !important;">
        <div class="marquee-inner-wrapper" style="width: 2,352px;height: 364px;" ref="marqueeContainer"
          :style="{ width: '2352px', height: '364px', transform: `scale(${scaledValue})`, opacity: currentItemId === currentIndex - 4 ? 1 : 0 }">
          <span class="out_team_title" style="color: #FFFFFF;">Partners</span>
          <!-- 第一行icon -->
          <div :class="{ 'first-marquee': isLoadAnimate }">
            <div class="icon_space"><img alt="SEARCHFI" src="@/assets/svg/partners/first_line/SEARCHFI.svg"></div>
            <div class="icon_space"><img alt="Dailybux" src="@/assets/svg/partners/first_line/Dailybux.svg"></div>
            <div class="icon_space"><img alt="Yooldo APP" src="@/assets/svg/partners/first_line/Yooldo APP.svg"></div>
            <div class="icon_space"><img alt="fandom" src="@/assets/svg/partners/first_line/fandom.svg"></div>
            <div class="icon_space"><img alt="nuvo" src="@/assets/svg/partners/first_line/nuvo.svg"></div>
            <div class="icon_space"><img alt="MATRX" src="@/assets/svg/partners/first_line/MATRX.svg"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/third_line/Seedify.svg" alt="Seedify"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/second_line/SKYVISION CAPITAL.svg"
                alt="SKYVISION CAPITAL"></div>
          </div>
          <!-- 第二行icon -->
          <div :class="{ 'second-marquee': isLoadAnimate }">
            <div class="icon_space"><img alt="CHAINCAPITAL" src="@/assets/svg/partners/first_line/CHAINCAPITAL.svg"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/second_line/SPGCENGTION.svg" alt="SPGCENGTION"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/second_line/ULTIVERSE.svg" alt="ULTIVERSE"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/second_line/MAGICALLY.svg" alt="MAGICALLY"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/second_line/chiliz.svg" alt="chiliz"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/second_line/Airfoil.svg" alt="Airfoil"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/second_line/Pneopin.svg" alt="Pneopin"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/second_line/TREKKi.svg" alt="TREKKi"></div>
          </div>
          <!-- 第三行icon -->
          <div :class="{ 'three-marquee': isLoadAnimate }">
            <div class="icon_space"><img src="@/assets/svg/partners/second_line/WATERDRIP CAPITAL.svg"
                alt="WATERDRIP CAPITAL"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/third_line/AOFVERSE.svg" alt="AOFVERSE"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/third_line/APELIST.svg" alt="APELIST"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/third_line/PG.svg" alt="PG"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/third_line/PETSPIA.svg" alt="PETSPIA"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/third_line/GRAPES.svg" alt="GRAPES"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/third_line/ISKPA.svg" alt="ISKPA"></div>
            <div class="icon_space"><img src="@/assets/svg/partners/third_line/lillius.svg" alt="lillius"></div>
          </div>
        </div>
        <div class="down_icon" @click="prevSlide" v-show="showTopIcon">
          <img class="max-icon" src="@/assets/svg/top-max2.svg">
          <!-- <img class="min-icon" style="position: absolute;left: 18px;" src="@/assets/svg/top-min.svg" @click="prevSlide"> -->

        </div>
      </el-carousel-item>

      <!-- 外层轮播 收购-->
      <el-carousel-item class="carousel-item" style="display: flex; flex-direction: column; align-items: center;">
        <div class="carousel-item-content" style="text-align: center;"
          :style="{ opacity: this.currentItemId === this.currentIndex - 5 ? 1 : 0 }">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <span class="title" style="font-size: 56px; line-height: 65.97px;">Acquisition of</span>
            <img src="@/assets/svg/SEARCHFI.svg" alt="SEARCHFI">
          </div>
          <span class="title_content" style="line-height: 50px;">SearchFi is a marketing team with a global NFT
            community</span>
        </div>
        <el-row class="acquisition_data" justify="center" align="middle" style="margin-left: 160px;"
          :style="{ opacity: this.currentItemId === this.currentIndex - 5 ? 1 : 0 }">
          <el-col :span="8">
            <div>
              <span>550k</span>
              <span>Users</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <span>3,000</span>
              <span>Collaborations</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="flex: 0.8; text-align: center;">
              <span>Covering</span>
              <span>East Asia, Southeast Asia, North America, & Africa</span>
            </div>
          </el-col>
        </el-row>

        <el-row class="three_icon" style="position: absolute;bottom: 17%;"
          :style="{ opacity: this.currentItemId === this.currentIndex - 5 ? 1 : 0 }">
          <el-col :span="6">
            <a href="https://discord.com/invite/searchfi" target="_blank">
              <img src="@/assets/svg/Discord.svg" alt=""
                style="padding: 14px; border: 1px solid #D3D3D3; border-radius: 20px;cursor: pointer;">
            </a>
          </el-col>
          <el-col :span="6">
            <a href="https://twitter.com/searchfi_eth" target="searchfi_eth">
              <img src="@/assets/svg/searchfi_eth.svg" alt=""
                style="padding: 14px; border: 1px solid #D3D3D3; border-radius: 20px;cursor: pointer;">
            </a>
          </el-col>

          <el-col :span="12">
            <el-button round class="learn_more" style="width: 160px; height: 48px;"><a href="https://searchfi.io/">Learn
                more</a></el-button>
          </el-col>
        </el-row>


      </el-carousel-item>

      <!-- 外层轮播 团队-->
      <el-carousel-item class="carousel-item" style="display: flex;">

        <el-row style="width: 960px; height: 260px;display: contents;">
          <el-col :span="12">
            <span class="out_team_title" style="bottom: 20px;position: relative;"
              :style="{ opacity: this.currentItemId === this.currentIndex - 6 ? 1 : 0 }">Our Team</span>
            <el-row class="our-team" style="display:flex; justify-content: space-around;">
              <!-- 左侧内容 -->
              <el-col :span="9" style="margin-right: 60px;"> <!-- 120px 的间隔，两列之间设置 margin-right -->
                <el-row justify="center" align="middle" style="display:flex;">
                  <img src="@/assets/svg/Q.svg" alt="Q"
                    :style="{ opacity: this.currentItemId === this.currentIndex - 6 ? 1 : 0 }">
                  <div style="flex-direction: column; align-items: flex-start; display: flex;justify-content: center;"
                    :style="{ opacity: this.currentItemId === this.currentIndex - 6 ? 1 : 0 }">
                    <span class="title_content creator-name">Q</span>
                    <span class="subtitle_content1">Founder & CEO</span>
                    <a href="https://twitter.com/Q_Ticker" target="Q_Ticker">
                      <span class="subtitle_content2">@Q_Ticker</span>
                    </a>
                  </div>
                </el-row>
              </el-col>
              <!-- 中间占位 -->
              <el-col :span="6">
                <div class="grid-content bg-purple-light"></div>
              </el-col>
              <!-- 右侧内容 -->
              <el-col :span="8" style="margin-left: 60px;"> <!-- 120px 的间隔，两列之间设置 margin-left -->
                <el-row justify="center" align="middle" style="display:flex;">
                  <img src="@/assets/svg/Apple.svg" alt="Apple"
                    :style="{ opacity: this.currentItemId === this.currentIndex - 6 ? 1 : 0 }">
                  <div style="flex-direction: column; align-items: flex-start; display: flex;justify-content: center;"
                    :style="{ opacity: this.currentItemId === this.currentIndex - 6 ? 1 : 0 }">
                    <span class="title_content creator-name">Apple</span>
                    <span class="subtitle_content1">CMO</span>
                    <a href="https://twitter.com/eth_apple" target="eth_apple">
                      <span class="subtitle_content2">@eth_apple</span>
                    </a>
                  </div>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-carousel-item>

      <!-- 外层轮播 Seoul, Hong Kong-->
      <el-carousel-item class="company_profile" style="background: #f5f5f5 !important;">
        <div class="seoul-box" :style="{ opacity: this.currentItemId === this.currentIndex - 7 ? 1 : 0 }">
          <img src="@/assets/svg/Q-big.svg">
          <img style="width: 208px;height: 32px;" src="@/assets/svg/Ticker_Capial_samll.svg">
          <div style="display: flex; flex-direction: column;">
            <span class="title_content" style="color: #4A4A4A !important;">Seoul, Hong Kong</span>
            <span class="title_content" style="color: #37588A !important;">
              <el-popover placement="bottom" width="200" trigger="click" v-model="popoverVisible">
                <!-- <span style="padding-left: 8px;cursor: pointer;" slot="reference" @mouseover="hoverButton"
                  @mouseleave="leaveButton">
                  Contact
                </span> -->
                <div>
                  <i class="el-icon-copy-document" style="cursor: pointer; margin-right: 10px;" v-show="popoverVisible"
                    @click="copyContent"></i>
                  <span>contact@ticker.capital</span>
                </div>
              </el-popover>

              <a href="https://twitter.com/0xTicker" target="0xTicker">@ticker.capital</a>
            </span>
          </div>
        </div>

      </el-carousel-item>
      <el-row style="top: 11.5%;justify-content: center;" v-show="showQblackIcon">
        <div class="q-image-class">
          <img class="Q-icon" src="@/assets/svg/Q-black-icon.svg" @click="prevSlide">
        </div>

      </el-row>
      <el-row style="top: 11.5%;justify-content: center;">
        <div class="q-image-class">
          <img class="Q-icon" src="@/assets/svg/Q-white-icon.svg" v-show="showDownWhiteIcon" @click="prevSlide">
        </div>

      </el-row>
      <DownIcon @click="nextSlide" v-show="showDownIcon"></DownIcon>
      <DownWhiteIcon @click="nextSlide" v-show="showDownWhiteIcon"></DownWhiteIcon>
      <!-- <TopIcon @click="prevSlide" v-show="showTopIcon"></TopIcon> -->
      <div class="down_icon" @click="prevSlide" v-show="showTopIcon">
        <div>
          <img class="max-icon" src="@/assets/svg/top-max2.svg">
          <!-- <img class="min-icon" style="position: absolute;left: 18px;" src="@/assets/svg/top-min.svg"> -->
        </div>

      </div>
    </el-carousel>
    <!-- 进度条 -->
    <div style="width:26px;">
      <div v-show="shouldShowProgressBar" class="progress-bar">
        <div class="progress" :style="{ height: progressPercentage + '%' }"></div>
        <div class="progress-text top title_content">{{ "0" + JSON.stringify(currentIndex - 1) }}</div>
        <div class="progress-text bottom title_content">03</div>
      </div>
    </div>
  </div>
</template>

<script>
import DownIcon from '../components/downIcon.vue';
import TopIcon from '../components/topIcon.vue';
import DownWhiteIcon from '../components/downWhiteIcon.vue';
import { debounce } from 'lodash';

export default {
  components: {
    DownIcon,
    TopIcon,
    DownWhiteIcon
  },
  data() {
    return {
      isAnimating_top: false,
      isAnimating_down: false,
      currentItemId: 1,
      progressPercentage: 0,
      outerItems: ['Item 1', 'Item 2', 'Item 3'],
      innerItems: [{
        "id": 1,
        "title": 'We are Builders',
        'title_content': "Leveraging deep industry knowledge, we provide expert consulting and advisory services tailored specifically to accelerate blockchain projects"
      }, {
        "id": 2,
        "title": 'We are Marketers',
        'title_content': "We support our partners and portfolio companies with strategies to enter key markets globally, especially within South Korea and broader Asia Pacific region"
      }, {
        "id": 3,
        "title": 'We are Long-Term Partners',
        'title_content': "We leverage our extensive network globally and strategic insights to facilitate partnerships that drive long-term mutual growth and success"
      }],
      showDownIcon: true,
      showDownWhiteIcon: false,
      showTopIcon: false,
      currentIndex: 0,
      totalItems: 0,
      isHovered: false,
      visible: false,
      popoverVisible: false,
      showQblackIcon: false,
      isScrolling: false,
      isLoadAnimate: false,
      scaledValue: 1 // 初始缩放值
    };
  },
  computed: {
    shouldShowProgressBar() {
      return this.currentIndex >= 2 && this.currentIndex <= 4;
    }
  },
  mounted() {
    this.checkResize();
    window.addEventListener('resize', this.checkResize);
  },
  methods: {
    checkResize() {
      this.$nextTick(() => {
        const marqueeContainer = this.$refs.marqueeContainer;
        const containerWidth = marqueeContainer.clientWidth;

        // 随着屏幕宽度减小，缩放值逐渐减小
        this.scaledValue = Math.max(0.5, containerWidth / 1550);
      });
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkResize);
    },
    copyContent() {
      const contentToCopy = "contact@ticker.capital";
      navigator.clipboard.writeText(contentToCopy)
        .then(() => {
          this.$message.success('内容已复制');
        })
        .catch((error) => {
          console.error('复制失败', error);
          this.$message.error('复制失败');
        });
    },
    hoverButton() {
      this.isHovered = true;
    },
    leaveButton() {
      this.isHovered = false;
    },
    handleOuterChange(index) {
      // index 参数即为当前轮播图的索引
      this.currentIndex = index
      // console.log(this.currentIndex);
      // 获取轮播图的总数
      this.totalItems = this.$refs.carousel.items.length;
      setTimeout(() => {
        if (this.currentIndex === 0) {
          this.showQblackIcon = false;
        }
      }, 0);
      if (this.currentIndex === 1) {
        this.showQblackIcon = false;
        this.showDownWhiteIcon = true;
      }
      if (this.currentIndex === 5) {
        this.isLoadAnimate = true;
      }
      // 判断是否 is 6 or 5
      if (this.currentIndex === 1 || this.currentIndex === 5) {
        this.showDownIcon = false;
        this.showDownWhiteIcon = true;
        this.showTopIcon = false;
        this.showQblackIcon = false
      } else if (this.currentIndex === 8) {
        // 判断是否是最后一个轮播图
        this.showTopIcon = true;
        this.showDownIcon = false;
        this.showDownWhiteIcon = false;
        this.showQblackIcon = false;
      } else {
        // All other cases
        this.showDownIcon = true;
        this.showDownWhiteIcon = false;
        this.showTopIcon = false;
        this.showQblackIcon = true;
        this.isLoadAnimate = false;
      }
      // 计算当前轮播图位置的百分比
      // console.log("this.currentIndex", this.currentIndex);
      this.progressPercentage = ((this.currentIndex - 1) / (this.totalItems)) * 100 * 3;
      // console.log(this.progressPercentage);
    },
    prevSlide() {
      let that = this

      this.$refs.carousel.prev();


    },
    nextSlide() {
      let that = this
      if (this.currentIndex == 0) {
        this.isAnimating_top = true;
        this.isAnimating_down = true;
        setTimeout(() => {
          that.$refs.carousel.next();
        }, 100);

        setTimeout(function () {
          that.isAnimating_top = false;
          that.isAnimating_down = false;
        }, 800)
      } else {
        that.$refs.carousel.next();
      }

    },
    handleWheel: debounce(function (event) {
      // console.log(event.deltaY);
      //  根据滚动方向切换轮播
      if (event.deltaY < 0) {
        this.prevSlide();
      } else {
        this.nextSlide();
      }
    }, 80)
  }
}
</script>

<style>
.icon_space {
  /* width: 180px; */
  /* flex: 0 0 auto; */
  /* 固定宽度 */
  flex: 0 0 180px;
  /* 固定宽度 */
  box-sizing: border-box;
  /* 防止添加边距导致元素宽度超出 */
}

.el-carousel__item.is-animating {
  transition: transform 1s ease-in-out !important;
}

.el-carousel-item div,
.el-carousel-item img,
.el-carousel-item span,
.el-carousel-item i,
.el-carousel-item a,
.item-content,
.carousel-item {
  transition: opacity 0.3s ease-in-out;
}

.first-marquee,
.second-marquee,
.three-marquee {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  /* margin: 0 20px; */
  /* overflow: hidden; */
}

.three_icon {
  gap: 8px;
  width: 304px;
  height: 48px;
  align-items: center;
}

.learn_more {
  border-radius: 20px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #787878;
  background: unset !important;
  position: relative;
  overflow: hidden;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 12px;
  left: -12px;
}

.learn_more::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #15255e;
  transition: height 0.3s ease;
  z-index: -1;
  /* 将 ::before 伪元素的 z-index 设置为较低值 */
}

.learn_more:hover::before {
  height: 100%;
}

.learn_more:hover {
  color: #FFFFFF !important;
  /* 鼠标悬停时的字体颜色 */
}

.top {
  top: -35px;
}

.bottom {
  bottom: -35px;

}

.progress-bar {
  position: absolute;
  top: 37.5%;
  right: 150px;
  height: 200px;
  width: 1px;
  background-color: #D9D9D9;
  z-index: 999;
}

.progress {
  background-color: #4A4A4A;
  transition: height 0.5s ease-in-out;
}

/** 设置宽，溢出隐藏  */
.marquee-outer-wrapper {
  overflow: hidden;
  width: 100%;
}

.marquee-inner-wrapper {
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin: 0 auto;
  white-space: nowrap;
  position: relative;
  height: 364px;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: grid;
}

/* 定义第一个span的animation：时长 动画名字 匀速 循环 ease-in-out 正常播放 */
.first-marquee {
  -webkit-animation: marquee-animation 1s ease-out none alternate;
  animation: marquee-animation 1s ease-out none alternate;
  transform: scale(1);
}

@keyframes marquee-animation {
  0% {
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%) scale(1);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) scale(1);
  }
}

.second-marquee {
  -webkit-animation: second-animation 1s ease-out none alternate;
  animation: second-animation 1s ease-out none alternate;
  transform: scale(1);
}

@keyframes second-animation {
  0% {
    -webkit-transform: translateX(110%);
    transform: translateX(110%) scale(1);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) scale(1);
  }
}

.three-marquee {
  -webkit-animation: three-animation 1s ease-out none alternate;
  animation: three-animation 1s ease-out none alternate;
  /* padding-right: -10%; */
  transform: scale(1);
}

@keyframes three-animation {
  0% {
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%) scale(1);
  }

  /* Move to the left */
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%) scale(1);
  }
}

.seoul-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 232px;
  gap: 24px;
}

.subtitle_content1 {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #4A4A4A;
  width: 119px;
  text-align: left;
}

.subtitle_content2 {
  font-family: 'Amiri';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #37588A;
  width: 119px;
  text-align: left;
}

.our_team {
  /* display: flex; */
  width: 960px;
  height: 260px;
}

.out_team_title {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  color: #4A4A4A;
}

.acquisition_data {
  /* display: flex; */
  width: 935.23px;
  height: 123.03px;
}

.acquisition_data div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.acquisition_data span:nth-child(2) {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 400;
  color: #4A4A4A;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #787878;
  padding-left: 4px;
}

.acquisition_data span:first-child {
  font-family: 'Amiri';
  font-size: 64px;
  font-weight: 400;
  font-style: italic;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: center;
  color: #4A4A4A;
}


.carousel-item-content {
  width: 639.07px;
  height: 138px;
}

.partners {
  /* display: flex; */
  flex-direction: column;
  /* align-items: center; */
}

.company_profile_content {
  width: 100%;
  height: 180px;
  display: block;
  justify-content: center;
  text-align: center;
}


.partners_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  font-family: 'Poppins';
  color: #FFFFFF;
}

.fingerprint {
  position: absolute;
  z-index: -1;
  top: 29.5%;
}

.el-divider--horizontal {
  bottom: 13px !important;
  width: 132px !important;
  margin: 0 auto !important;
  border-top: 1px #4A4A4A var(--el-border-style) !important;
}

.el-divider {
  background-color: #4A4A4A !important;
}

.title {
  font-family: 'Italiana';
  font-size: 64px;
  font-weight: 400;
  line-height: 75.39px;
  letter-spacing: 0em;
  color: #4A4A4A;
}

.title_content {
  font-family: 'Amiri';
  color: #787878;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.inner-item-content {
  width: 600px;
  height: 211px;
  display: grid;
  grid-template-rows: 3fr 1fr;
  align-items: center;
  position: absolute;
}

.background-image-top {
  background-image: url('@/assets/svg/background-top.svg');
  /* 设置为 background-top.svg 的路径 */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.background-image-top.animate_top {
  animation: image-top 1s;
  -webkit-animation: image-top 1s;
  /* Safari and Chrome */
}

@keyframes image-top {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    bottom: 90%;
    top: -100%;
  }
}

@-webkit-keyframes image-top

/* Safari and Chrome */
  {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    bottom: 90%;
    top: -100%;
  }
}

.background-image-down {
  background-image: url('@/assets/svg/background-down.svg');
  /* 设置为 background-top.svg 的路径 */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -2;
}

.background-image-down.animate_down {
  animation: image-down 1s;
  -webkit-animation: image-down 1s;
  /* Safari and Chrome */
}

@keyframes image-down {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    bottom: -100%;
    top: 90%;
  }
}

@-webkit-keyframes image-down

/* Safari and Chrome */
  {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    bottom: -100%;
    top: 90%;
  }
}

.Q-icon {
  Width: 56px;
  Height: 56px;
  position: relative;
  cursor: pointer;
  top: 11.5%;
  /* right: 48%; */
  z-index: 999;
}

.grow_together {
  font-family: 'Amiri';
  font-size: 32px;
  font-weight: 400;
  line-height: 56.26px;
  letter-spacing: 0em;
  color: #787878;
}

.carousel-item {
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background: #f5f5f5 !important;
}

.company_profile {
  background: #272727 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.partners_item {
  background: #272727 !important;
  display: grid !important;
  align-items: center !important;
}

.item-content {
  width: 498px;
  height: 112px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #f5f5f5;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #eaeaea;
}

.creator-name {
  font-family: Amiri;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 8px;
}

.q-image-class {
  margin-right: 10px;
}

body {
  margin: 0 !important;
}
</style>
