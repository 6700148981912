<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import Watermark from "../src/utils/warterMarkJS.js";
export default {
  //初始化加载
  // mounted() {
  //   Watermark.set("我 是 水 印 ~");//添加水印名称
  // },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* src/styles/fonts.css */

@font-face {
  font-family: 'Italiana';
  src: url('../src/assets/fonts/Italiana/Italiana-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Amiri';
  src: url('../src/assets/fonts/Amiri/Amiri-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.down_icon {
  position: absolute;
  cursor: pointer;
  bottom: 8.5%;
  display: flex;
  z-index: 999;
  width: 100%;
  justify-content: center;
}

.down_icon mix-icon {
  width: 26.95px;
  height: 10.06px;
}

.down_icon max-icon {
  Width: 64px;
  Height: 24px;
}

/* 通过选择器选择<a>标签，并清除相关样式 */
a {
  text-decoration: none;
  /* 清除文本下划线 */
  color: inherit;
  /* 继承父元素的颜色 */
  cursor: default;
  /* 设置鼠标样式为默认 */
}

a:hover {
  cursor: pointer;
  /* 鼠标悬停时的样式 */
}

.el-carousel__item.is-animating {
  transition: transform 0.1s ease-in-out !important;
}
</style>
